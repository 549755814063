import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import axios from 'axios';
import { encode } from 'iconv-lite';

// ダウンロード処理
// isHeader:ヘッダー出力しない場合はtrue
export const downloadCsvFile = async (
  columns: { header: string; key: string }[],
  rows: any[],
  fileName: string,
  sheetName?: string,
  isHeader = false
) => {
  const workbookSheetName = sheetName ?? 'sheet1';
  const workbook = new ExcelJS.Workbook();
  workbook.addWorksheet(workbookSheetName);
  const worksheet = workbook.getWorksheet(workbookSheetName);
  if (worksheet) {
    worksheet.columns = columns;
    if (isHeader) {
      worksheet.spliceRows(1, 1, []);
    }
    worksheet.addRows(rows);
  }
  const csvWorkbook = await workbook.csv.writeBuffer();
  const arrayToString = csvWorkbook.toString();
  const sjisBuff = encode(arrayToString, 'Shift_JIS');
  const blob = new Blob([sjisBuff], { type: 'application/octet-binary' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  a.remove();
};

// URLからCSVダウンロード
export const downloadFromUrl = (csvName: string, csvUrl: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', csvUrl);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    saveAs(xhr.responseURL, csvName);
  };
  xhr.send();
};

// URLからCSVダウンロード ※ダウンロード完了するまでwaitする
export const downloadFromUrlPromise = async (csvName: string, csvUrl: string) => {
  const response = await axios.get(csvUrl, { responseType: 'blob' });
  const blob = new Blob([response.data], { type: 'text/csv' });
  saveAs(blob, csvName);
};
