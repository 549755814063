import React, { useMemo } from 'react';
import { VtBelongList } from 'graphql/graphql-ow';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { usePagenation } from 'pages/s03/components/usePagenate';
import { PaginationBox } from 'components/pagination';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { List } from 'components/list';
import { ListHeaderType, ListItemsType } from 'pages/s03/type';
import { Icon } from 'components/icon';
import { ChangeSmallImgWithStatus } from 'pages/s03/components/changeSmallImgWithStatus';
import { MemberInfoModal } from 'pages/s03/components/modal';
import { MemberStatus } from 'pages/s03/const';
import { useSort } from 'components/utils';
import { orderBy } from 'lodash';

/**
 * Figma ID: 03-05-01
 * 所属
 * Belong
 */

enum ModalType {
  NONE = 'NONE',
  MEMBER_INFO = 'MEMBER_INFO',
}
enum EmailMaxLength {
  NORMAL = 18,
}

export function Content(props: { data: (VtBelongList | null)[] | null; label: string; belongNum: number }) {
  const { data, label, belongNum } = props;
  const { items, handleChangeLimit, handleChangePage, pagenation } = usePagenation<VtBelongList | null>(
    data ?? [],
    10,
    1
  );
  const { sort, handleChangeSort } = useSort();
  const [modalType, setModalType] = React.useState<ModalType>(ModalType.NONE);
  const [modalId, setModalId] = React.useState<{ id: number; tpmemId: number }>({ id: 0, tpmemId: 0 });

  const listHeader: ListHeaderType = [
    { width: 24, key: 'eye' },
    { width: 40, key: 'image' },
    { width: 120, key: 'name', columnName: '氏名' },
    { width: 56, key: 'age', columnName: '年齢' },
    { width: 70, key: 'score', columnName: 'スコア' },
    { width: 70, key: 'rank_name', columnName: 'ランク' },
    { width: 104, key: 'attribute', columnName: '属性' },
    { width: 104, key: 'worked_count', columnName: '利用回数' },
    { width: 110, key: 'cancel_per', columnName: 'キャンセル率' },
    { width: 170, key: 'email', columnName: 'メールアドレス(ID)' },
    { width: 120, key: 'phone', columnName: '電話番号' },
    { width: 120, key: 'birthday', columnName: '生年月日' },
    { width: 56, key: 'gender', columnName: '性別' },
    { width: 240, key: 'address', columnName: '住所' },
    { width: 156, key: 'tpmem_name', columnName: '所属店舗' },
    { width: 120, key: 'begin_date', columnName: '所属店舗登録日' },
    { width: 156, key: 'sub_name', columnName: 'サブ所属店舗' },
    { width: 100, key: 'uniform_size', columnName: '制服サイズ' },
    { width: 92, key: 'btmem_number', columnName: '会員番号' },
  ];

  const generateEmail = (email?: string | null) => {
    const maxLength = EmailMaxLength.NORMAL;
    let slicedEmail = email;
    if (slicedEmail && slicedEmail?.length >= maxLength) {
      slicedEmail = `${String(email)?.slice(0, maxLength)}･･･`;
    }
    return <Text variant="caption12">{slicedEmail}</Text>;
  };

  const listItems: ListItemsType = useMemo(
    () =>
      orderBy(items, sort?.key, sort?.direction).map((item, key) => ({
        eye: (
          <Icon
            name="eye"
            onClick={() => {
              setModalId({ id: item?.id ?? 0, tpmemId: item?.tpmem_id ?? 0 });
              setModalType(ModalType.MEMBER_INFO);
            }}
          />
        ),
        image: (
          <ChangeSmallImgWithStatus
            status={item?.status === MemberStatus.Active ? MemberStatus.Active : MemberStatus.Suspended}
          >
            <img src={item?.image || ''} alt="" width={40} height={40} />
          </ChangeSmallImgWithStatus>
        ),
        name: item?.name,
        age: item?.age,
        score: item?.score,
        rank_name: item?.rank_name,
        attribute: item?.attribute,
        worked_count: item?.worked_count,
        cancel_per: `${item?.cancel_per ?? 0}%`,
        email: generateEmail(item?.email),
        phone: item?.phone,
        birthday: item?.birthday,
        gender: item?.gender,
        address: `〒${item?.zip_code ?? ''} ${item?.state ?? ''}${item?.city ?? ''}
        ${item?.address ?? ''}${item?.address2 ?? ''}`,
        tpmem_name: item?.tpmem_name,
        begin_date: item?.begin_date,
        sub_name: item?.sub_name,
        uniform_size: item?.uniform_size,
        btmem_number: item?.btmem_number,

        uniqueKey: key,
        backgroundColor: item?.status === 4 ? 'red' : 'white',
      })),
    [items, sort]
  );

  return (
    <>
      <Box display="flex" flex="1" flexDirection="column" width="100%">
        <Box display="flex" height={60} alignItems="center">
          <Text color="darkBlue" variant="h2">{`${label}(${belongNum})`}</Text>
        </Box>
        <Divider option="horizontal" />
        <Box flexDirection="column" flex="auto">
          <PaginationBox
            dataSize={data?.length ?? 0}
            limit={pagenation.limit}
            page={pagenation.page}
            onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
            onChangePage={(value: number) => handleChangePage(value)}
          >
            <ScrollWrapper bottom={411}>
              <List header={listHeader} items={listItems} onChangeSort={handleChangeSort} sort={sort} />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      </Box>
      <MemberInfoModal
        isOpen={modalType === ModalType.MEMBER_INFO}
        onClose={() => setModalType(ModalType.NONE)}
        {...modalId}
        label={label}
      />
    </>
  );
}
