import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import React, { useEffect } from 'react';
import { useGetVtMemberBaseByIdLazyQuery } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { MemberStatus } from 'pages/s03/const';
import { Info } from 'components/info';
import { InfoItemsType } from 'pages/s03/type';
import { Divider } from 'components/newDivider';
import { useNavigate } from 'react-router-dom';
import NoImageLarge from 'components/assets/images/no-image-large.svg';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';

import { ChangeBigImgWithStatus } from '../changeBigImgWithStatus';

export function MwStoreInfoModal(props: { isOpen: boolean; onClose: () => void; tpmemId: number }) {
  const { isOpen, onClose, tpmemId } = props;
  const navigate = useNavigate();
  const { openErrorModal } = useErrorModal();
  const [
    getVTMemberBaseById,
    { data: vTMemberBaseByIdData, loading: vTMemberBaseByIdLoading, error: vTMemberBaseByIdError },
  ] = useGetVtMemberBaseByIdLazyQuery();
  useEffect(() => {
    if (tpmemId !== undefined && tpmemId !== 0) {
      getVTMemberBaseById({ variables: { id: tpmemId } });
    }
  }, [tpmemId, getVTMemberBaseById]);

  useEffect(() => {
    if (vTMemberBaseByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [vTMemberBaseByIdError, openErrorModal]);

  const memberBase = vTMemberBaseByIdData?.getVTMemberBaseById;
  const bank = `${memberBase?.bank ?? ''} ${memberBase?.branch_name ?? ''} ${memberBase?.account_type ?? ''} ${
    memberBase?.account_no ?? ''
  } ${memberBase?.account_name ?? ''}`;
  const mcoItems = [
    {
      label: '法人番号',
      content: memberBase?.corporate_no,
    },
    {
      label: '電話番号',
      content: memberBase?.mco_phone,
    },
    {
      label: '代表者',
      content: memberBase?.mco_m_name,
    },
    {
      label: '',
      content: memberBase?.mco_m_kana,
    },
    {
      label: '担当者',
      content: memberBase?.mco_t_name,
    },
    {
      label: '',
      content: memberBase?.mco_t_kana,
    },
    {
      label: '担当者電話番号',
      content: memberBase?.mco_t_phone,
    },
  ];
  const mcoAddress = `〒 ${memberBase?.mco_zip_code ?? ''} ${memberBase?.mco_state ?? ''} ${
    memberBase?.mco_city ?? ''
  } ${memberBase?.mco_address ?? ''} ${memberBase?.mco_address2 ?? ''}`;
  const managedCompany = () => (
    <>
      <Box pb={8}>
        <Text variant="body14">{memberBase?.mco_name}</Text>
      </Box>
      <Box pb={8}>
        <Text variant="body14">{mcoAddress}</Text>
      </Box>
      {mcoItems.map((val) => (
        <Box display="flex" pb={8}>
          <Box width={112}>
            <Text variant="body14" color="darkGray">
              {val.label}
            </Text>
          </Box>
          <Text variant="body14">{val.content}</Text>
        </Box>
      ))}
    </>
  );

  const infoItems: InfoItemsType = [
    { label: '店舗コード', content: memberBase?.code },
    { label: '店舗名', content: memberBase?.store_name },
    {
      label: '住所',
      content: `〒${memberBase?.zip_code?.slice(0, 3) ?? ''}-${memberBase?.zip_code?.slice(3, 7) ?? ''} ${
        memberBase?.state ?? ''
      }${memberBase?.city ?? ''}${memberBase?.address ?? ''}${memberBase?.address2 ?? ''}`,
    },
    { label: '電話番号', content: memberBase?.phone },
    { label: 'メールアドレス(ID)', content: memberBase?.email },
    { label: '営業時間', content: memberBase?.opening },
    { label: '駐車場', content: memberBase?.parking },
    { label: '責任者氏名', content: memberBase?.manager },
    { label: '責任者電話番号', content: memberBase?.mgr_phone },
    {
      label: '事業者',
      content: managedCompany(),
    },
    { label: '業種', content: memberBase?.biz_name },
    { label: 'ブランド', content: memberBase?.brand_name },
    { label: 'またバイトしたい', content: memberBase?.again },
    { label: '友達招待コード', content: memberBase?.invite_code },
    { label: '総雇用回数', content: memberBase?.total_adopt },
    { label: 'お気に入り登録数', content: memberBase?.favorite_num },
    { label: '口座情報', content: bank },
    { label: '加盟日', content: memberBase?.join_date },
    { label: '退会日', content: memberBase?.quit_date },
    { label: '休止日', content: memberBase?.pause_date },
    { label: '再開日', content: memberBase?.restart_date },
    { label: '新規登録日', content: memberBase?.created_date },
  ];

  const modalContent = {
    header: (
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Text variant="h2" color="darkBlue">
          {memberBase?.store_name}
        </Text>
        <MainButton
          variant="secondary"
          width={74}
          onClick={() => {
            onClose();
            navigate(`/s03/${memberBase?.id ?? 0}`);
          }}
        >
          詳しく
        </MainButton>
      </Box>
    ),
    content: (
      <Box display="flex" flexDirection="column" rowGap={8} pa={0} width="100%">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '24px',
            alignItems: 'center',
            height: '60px',
            backgroundColor: `${memberBase?.status === MemberStatus.Suspended ? 'rgba(229, 0, 0, 0.1)' : ''}`,
          }}
        >
          <ChangeBigImgWithStatus status={memberBase?.status}>
            <img src={memberBase?.image ?? NoImageLarge} alt="" style={{ width: '90px', height: '60px' }} />
          </ChangeBigImgWithStatus>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Text color="darkBlue" variant="h1">
              {memberBase?.store_name}
            </Text>
          </Box>
        </div>
        <Divider option="horizontal" />
        <Info items={infoItems} />
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {vTMemberBaseByIdLoading && <Loading />}
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 1003,
    height: '90vh',
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={modalContent.header}
      content={modalContent.content}
      footer={modalContent.footer}
      width={modalContent.width}
      height={modalContent.height}
    />
  );
}
