import { Box } from 'components/box';
import { ToggleButton } from 'components/toggleButton';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Text } from 'components/text';
import { Card } from 'components/card';
import { SubMenu } from 'components/subMenu';
import * as gql from 'graphql/graphql-ow';
import { BitravelerInfoStatus, MemberStatus } from 'components/const';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { MainButton } from 'components/mainButton';
import NoImgSvg from 'components/assets/images/no-image-small.svg';
import { Modal, ImageModal } from 'components/newModal';
import { RecruitInfo, Review, UsageHistory, FavoriteStore, Withholding, CancelPer } from './components/index';
import { ChangeBigImgWithStatus } from './components/changeBigImgWithStatus';
import { StatusChangeModal } from './components/modal/statusChangeModal';
import { StatusChangeModalStep } from '../constants';

/**
 * Figma ID: 05-02-01, 05-02-05 休止中, 05-04-01,
 *           05-05-01, 05-06-01, 05-07-01, 05-08-01
 * 名称: バイトラベル会員
 */

export function S05Details() {
  const params = useParams();
  const [getMemberBase, { data: memberBaseData, loading: memberBaseLoading, error: memberBaseError }] =
    gql.useGetupdVbMemberBaseByIdMutation({
      variables: { id: Number(params.id) },
    });

  const [
    restoreToTemporarySuspension,
    { data: restorationData, loading: restorationLoading, error: restorationError },
  ] = gql.useUpdateBMemberStatusByIdMutation();

  const [activeStatusInactiveUpdate, { loading: activeLoading, error: activeError }] =
    gql.useUpdateBMemberStatusPauseDateByIdMutation();

  const [inactiveStatusInactiveUpdate, { loading: inactiveLoading, error: inactiveError }] =
    gql.useUpdateBMemberStatusRestartDateByIdMutation();

  const [toggleState, setToggleState] = useState<BitravelerInfoStatus>();
  const { openErrorModal } = useErrorModal();
  const [modalStep, setModalStep] = React.useState<StatusChangeModalStep>(StatusChangeModalStep.NULL);

  const onClose = useCallback(() => {
    setModalStep(StatusChangeModalStep.NULL);
  }, []);

  const cancelHandler = useCallback(() => {
    setToggleState(
      toggleState === BitravelerInfoStatus.ACTIVE ? BitravelerInfoStatus.PAUSE : BitravelerInfoStatus.ACTIVE
    );
    setModalStep(StatusChangeModalStep.NULL);
  }, [toggleState]);

  const activeHandler = useCallback(() => {
    activeStatusInactiveUpdate({ variables: { id: Number(params.id) } }).then(() => {
      getMemberBase();
      setModalStep(StatusChangeModalStep.COMPLETE);
    });
  }, [activeStatusInactiveUpdate, params.id, getMemberBase]);

  const inactiveHandler = useCallback(() => {
    inactiveStatusInactiveUpdate({ variables: { id: Number(params.id) } }).then(() => {
      getMemberBase();
      setModalStep(StatusChangeModalStep.COMPLETE);
    });
  }, [inactiveStatusInactiveUpdate, params.id, getMemberBase]);

  const statusChangeModal = StatusChangeModal({
    modalStep,
    onClose,
    cancelHandler,
    activeHandler,
    inactiveHandler,
  });

  const [imageModalStep, setImageModalStep] = useState<boolean>(false);

  useEffect(() => {
    getMemberBase();
  }, [getMemberBase]);

  useEffect(() => {
    if (memberBaseData) {
      const newToggleState =
        memberBaseData.getupdVBMemberBaseById.status === 1 ? BitravelerInfoStatus.ACTIVE : BitravelerInfoStatus.PAUSE;

      setToggleState(newToggleState);
    }
  }, [memberBaseData]);

  if (memberBaseError) {
    openErrorModal({ message: 'データの取得に失敗しました。' });
  }

  if (restorationError) {
    openErrorModal({ message: '復旧に失敗しました。' });
  }

  if (activeError) {
    openErrorModal({ message: '休止変更に失敗しました。' });
    setModalStep(StatusChangeModalStep.NULL);
  }

  if (inactiveError) {
    openErrorModal({ message: '再開変更に失敗しました。' });
    setModalStep(StatusChangeModalStep.NULL);
  }

  return (
    <>
      {(memberBaseLoading || restorationLoading || activeLoading || inactiveLoading) && <Loading />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '60',
          marginBottom: '24px',
          backgroundColor: `${toggleState === BitravelerInfoStatus.PAUSE ? 'rgba(229, 0, 0, 0.1)' : ''}`,
        }}
      >
        <Box display="flex" gap={20} height="100%" alignItems="center">
          {memberBaseData?.getupdVBMemberBaseById.status !== BitravelerInfoStatus.ACTIVE ? (
            <ChangeBigImgWithStatus status={memberBaseData?.getupdVBMemberBaseById.status}>
              <img
                src={memberBaseData?.getupdVBMemberBaseById.image || NoImgSvg}
                alt=""
                style={{ width: '60px', height: '60px' }}
              />
            </ChangeBigImgWithStatus>
          ) : (
            <button
              onClick={() => {
                setImageModalStep(true);
              }}
              type="button"
              style={{ width: '60px', height: '60px', padding: 0, border: 'none', background: 'none' }}
              aria-label="Open Image Modal"
            >
              <img
                src={memberBaseData?.getupdVBMemberBaseById.image || NoImgSvg}
                alt=""
                style={{ width: '60px', height: '60px' }}
              />
            </button>
          )}
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Text color="darkBlue" variant="h1">
              {memberBaseData?.getupdVBMemberBaseById.name ?? ''}
              {memberBaseData?.getupdVBMemberBaseById.status === BitravelerInfoStatus.TEMPORARY_PAUSE
                ? `（${memberBaseData?.getupdVBMemberBaseById?.pause_end_day || ''}まで）`
                : ''}
            </Text>
          </Box>
        </Box>
        {memberBaseData?.getupdVBMemberBaseById.status === BitravelerInfoStatus.STOP ? (
          <MainButton
            onClick={() => {
              // ステータスを一時利用停止に変更
              restoreToTemporarySuspension({
                variables: { id: Number(params.id), status: MemberStatus.TEMPORARY_SUSPENSION },
              }).then(() => {
                // 画面上の情報を更新
                getMemberBase();
              });
            }}
          >
            復旧
          </MainButton>
        ) : null}
        {memberBaseData?.getupdVBMemberBaseById.status !== BitravelerInfoStatus.TEMPORARY_PAUSE &&
        memberBaseData?.getupdVBMemberBaseById.status !== BitravelerInfoStatus.STOP ? (
          <ToggleButton
            currentValue={String(toggleState)}
            onClick={(value) => {
              setToggleState(Number(value));
              setModalStep(Number(value));
            }}
            options={[
              { label: '休止中', value: `${String(BitravelerInfoStatus.PAUSE)}`, activeColor: '#E50000' },
              { label: 'アクティブ', value: `${String(BitravelerInfoStatus.ACTIVE)}` },
            ]}
          />
        ) : null}
      </div>
      <Box display="flex" height="calc(100% - 85px)">
        <Card overflow="hidden">
          <SubMenu
            items={[
              {
                content: (
                  <RecruitInfo
                    id={params.id ?? ''}
                    refetchName={() => {
                      getMemberBase();
                    }}
                  />
                ),
                label: '基本情報',
              },
              {
                content: <Review id={params.id ?? ''} />,
                label: 'レビュー',
              },
              {
                content: <UsageHistory id={params.id ?? ''} />,
                label: '利用履歴',
              },
              {
                content: <FavoriteStore id={params.id ?? ''} />,
                label: 'お気に入り店舗',
              },
              {
                content: <Withholding id={params.id ?? ''} />,
                label: '源泉徴収票一覧',
              },
              {
                content: <CancelPer id={params.id ?? ''} />,
                label: 'キャンセル率',
              },
            ]}
          />
        </Card>
      </Box>
      <Modal
        height={statusChangeModal.height}
        width={statusChangeModal.width}
        isOpen={modalStep !== StatusChangeModalStep.NULL}
        content={statusChangeModal.content}
        header={statusChangeModal.header}
        footer={statusChangeModal.footer}
        onClose={statusChangeModal.onClose}
      />
      <ImageModal
        src={memberBaseData?.getupdVBMemberBaseById.image || ''}
        isOpen={imageModalStep}
        onClose={() => setImageModalStep(false)}
      />
    </>
  );
}
